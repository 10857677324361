<template>
    <div>
        <Toolbar :handle-reset="resetForm" :handle-submit="onSendForm"></Toolbar>
        <TenantForm :errors="violations" :values="item" ref="createForm"/>
        <Loading :visible="isLoading"/>
    </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { createHelpers } from 'vuex-map-fields';
  import TenantForm from '../../components/tenant/CreateForm';
  import Loading from '../../components/Loading';
  import Toolbar from '../../components/Toolbar';
  import CreateMixin from '../../mixins/CreateMixin';

  const servicePrefix = 'Tenant';

  const { mapFields } = createHelpers({
    getterType: 'tenant/getField',
    mutationType: 'tenant/updateField'
  });

  export default {
    name: 'TenantCreate',
    servicePrefix,
    mixins: [CreateMixin],
    components: {
      Loading,
      Toolbar,
      TenantForm
    },
    data () {
      return {
        item: {
          profile:{}
        }
      };
    },
    computed: {
      ...mapFields(['error', 'isLoading', 'created', 'violations'])
    },
    methods: {
      ...mapActions('tenant', ['create', 'reset']),
      getItemSubs(){
        return {
          profile: {}
        }
      }
    }
  };
</script>
